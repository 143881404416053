@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Gilroy, sans-serif;
}

.swal-icon--success {
    border-color: #fff;
}
.swal-icon--success:after,
.swal-icon--success:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: #a5dc86;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.swal-icon--success:before {
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
}
.swal-icon--success:after {
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 60px;
    transform-origin: 0 60px;
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
}
.swal-icon--success__ring {
    width: 80px;
    height: 80px;
    border: 4px solid hsla(98, 55%, 69%, 0.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}
.swal-icon--success__hide-corners {
    width: 5px;
    height: 90px;
    background-color: #fff;
    padding: 1px;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.swal-icon--success__line {
    height: 5px;
    background-color: #ffffff;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 3;
}
.swal-icon--success__line--tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
}
.swal-icon--success__line--long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
}
@-webkit-keyframes rotatePlaceholder {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
    to {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
}
@keyframes rotatePlaceholder {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
    to {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
}
@-webkit-keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    to {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}
@keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    to {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}
@-webkit-keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }
    to {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
@keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }
    to {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
.swal-icon {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    margin: 20px auto;
}

.app-container {
    width: 80%;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
}

.App-embed {
    margin-bottom: 50px;
    margin-top: 30px;
}

.integry-container {
    width: 100%;
}

.nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    z-index: 10;
}

.w-nav-link {
    margin-right: 0px;
    margin-left: 0px;
    color: #082d45;
    font-weight: 400;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 20px;
}

.mobile-menu {
    display: none;
}

.primarybutton {
    border-radius: 8px;
    background-color: #082d45;
    font-family: Gilroy, sans-serif;
    color: #fff;
    line-height: 20px;
    font-weight: 600;
    text-align: center;

    height: 50px;
    max-width: 168px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px 40px;
}

.footer {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    box-sizing: border-box;
    padding-top: 125px;
    padding-bottom: 125px;
    border-bottom: 1px solid #57cc99;
    background-color: #082d45;
}

.footer-top-div-2 {
    display: flex;
    align-items: center;
}

.footer-top-bar {
    display: flex;
    justify-content: space-between;
}

.footer-links {
    display: flex;
}

.footer-link {
    margin-right: 20px;
    color: #80ed99;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-decoration: none;
}

.separator {
    margin-top: 25px;
    margin-bottom: 25px;
    border-style: solid none none;
    border-width: 1px;
    border-color: #8f9bb3 #000 #000;
    width: 100%;
}

.separator.mobile {
    display: none;
}

.social-links {
    display: flex;
    gap: 15px;
}

.footer-bottom-div {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.locations-div {
    display: flex;
    gap: 32px;
    align-items: center;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

.copyrights-div {
    display: flex;
    gap: 32px;
    color: #8f9bb3;
    align-items: center;
}

.mob-menu-btn {
    display: none;
}

.hidden {
    display: none !important;
}

.error-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    margin-top: 5%;
}

.error-icon {
    width: 96px;
    height: 96px;
    color: #38a3a5;
}

.error-heading {
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    color: #22577a;
    margin-top: 30px;
}

.error-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #082d45;
    margin-top: 8px;
}

.error-action {
    padding: 15px 40px;
    gap: 10px;
    background: #082d45;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 40px;
}

.error-bottom-design {
    width: 100%;
    margin-top: 20px;
}

.choose-integration {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e3a59;

    margin-top: 40px;
}

@media only screen and (width <= 1000px) {
    .app-container {
        width: auto;
        max-width: 95%;
    }

    .footer {
        padding: 24px 0px;
    }
    .mob-menu-btn {
        display: block;
    }

    .main-menu-btn-desktop {
        display: none;
    }

    .w-nav-menu {
        display: flex;
        flex-direction: column;
    }

    .desktop-menu {
        display: none;
    }

    .mobile-menu {
        display: flex;
        height: 100vh;
        width: 100vw;
        flex-direction: column;
        align-items: center;
        background-color: white;
        top: 0;
    }

    .main-menu-btn-mobile {
        max-width: unset;
        width: 95%;
        display: block;
    }

    .error-icon {
        width: 80px;
        height: 80px;
    }

    .error-heading {
        font-size: 40px;
        line-height: 50px;
    }

    .error-action {
        width: 100%;
    }

    .footer-top-bar {
        flex-direction: column;
        gap: 10px;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
        gap: 30px;

        padding-bottom: 30px;
        border-bottom: 1px solid #80ed99;
    }

    .footer-top-div-2 {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        gap: 30px;
    }

    .footer-bottom-div {
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        padding-bottom: 80px;
    }

    .social-links {
        margin-top: 20px;
    }

    .text-block-17 {
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }

    .separator.desktop {
        display: none;
    }

    .separator.mobile {
        display: block;
    }
}
